const moveArrayElement = <T>(data: T[], from: number, to: number): T[] => {
    if (from < 0) {
        return data;
    }
    // remove `from` item and store it
    const f = data.splice(from, 1)[0];

    if (f === undefined) {
        return data;
    }
    // insert stored item into position `to`
    data.splice(to, 0, f);

    return data;
};

export default moveArrayElement;
