import type { ForwardedRef } from 'react';
import { useState } from 'react';
import type React from 'react';
import type { Tracker } from '@yoursurprise/segment-analytics';
import { useInfiniteHits } from 'react-instantsearch-core';
import Button from '../../../../stories/Atoms/Button/Button';
import Flex from '../../../../stories/Atoms/Flex/Flex';
import Translation from '../../../general/Translation/Translation';
import { MemoProductBox } from '../../../general/ProductBox/ProductBox';
import { useWithStaticUrl } from '../../../general/WebshopContext/WebshopContext';
import ButtonStack from '../../../general/Button/ButtonStack';
import Section from '../../../general/Section/Section';
import useTranslate from '../../../general/Translation/hooks/UseTranslate';
import type { AlgoliaProduct } from '../Input';
import { createOnProductClicked } from '../createOnProductClicked';
import { window } from '../../../../js/globals';
import Icon from '../../../../stories/Atoms/Icon/Icon';

export type Cache = {
    read: (...input: unknown[]) => unknown;
    write: (...input: unknown[]) => unknown;
};

interface InfiniteHitsProps {
    collapsed?: boolean;
    currency: string;
    distinct?: boolean;
    editableButton?: React.ReactElement;
    forwardRef?: ForwardedRef<HTMLDivElement | null>;
    hasFreeCoolerNotification?: boolean;
    hidePagination?: boolean;
    imageOverrides: Record<number, string>;
    photoGiftUrl: string;
    tracker?: Tracker | undefined;
}

const Results: React.FC<InfiniteHitsProps> = ({
    collapsed,
    currency,
    distinct,
    editableButton,
    forwardRef,
    hidePagination,
    imageOverrides,
    photoGiftUrl,
    tracker,
}) => {
    const {
        isLastPage,
        items,
        results,
        showMore: refineNext,
    } = useInfiniteHits<AlgoliaProduct>();

    const { index, nbHits: totalResults } = results ?? { index: '', nbHits: 0 };
    const hasMore = !isLastPage;
    const [isCollapsed, setIsCollapsed] = useState(collapsed);

    const onProductClicked = createOnProductClicked(tracker, currency, index);
    const withStaticUrl = useWithStaticUrl();

    // We need to make sure that the initial hits contain the correct queryId, which is retrieved from a subsequent request to Algolia
    // Algolia bases the initial queryId on the server-side queryId, which is cached, we want to send the client side one as it needs to be unique
    items.forEach((item) => {
        // eslint-disable-next-line no-param-reassign
        item.__queryID = item.__queryID ?? results?.queryID;
    });

    const giftsViewed = useTranslate()('overview_page', 'gifts_viewed')
        .replace('<current>', items.length.toString())
        .replace('<total>', totalResults.toString());

    return (
        <Section isSm hasNoPaddingXs isRelative={isCollapsed} ref={forwardRef}>
            {isCollapsed && (
                <div className="product-list__collapsed" />
            )}
            {items.length ? (
                <>
                    <div className="product-list is-grid has-col-4-xl has-col-3-md has-col-2 has-row-gap-md js-productList">
                        {items.map((item, position) => {
                            const image = imageOverrides[item.productId] ? withStaticUrl(imageOverrides[item.productId] ?? '') : item.image;
                            if (isCollapsed && position > 3) {
                                return null;
                            }

                            return (
                                <MemoProductBox
                                    alt={item.productH1}
                                    key={item.productId}
                                    editableButton={editableButton}
                                    amount={distinct ? item.combiArticleCount : 0}
                                    href={item.url}
                                    image={image}
                                    onProductClicked={() => onProductClicked(item, position)}
                                    price={{ currency, value: item.price.current }}
                                    priceFrom={{ currency, value: item.price.from }}
                                    lowestPrice={distinct && item.combiArticleCount > 1 ? {
                                        currency,
                                        value: item.lowestPrice,
                                    } : undefined}
                                    title={distinct ? item.name : item.productTitle}
                                    productId={item.productId}
                                    hasFreeCoolerNotification={item.hasFreeCoolerNotification}
                                />
                            );
                        })}
                    </div>
                    {isCollapsed ? (
                        <div className="product-list__load">
                            <Flex alignItems="center" direction="column">
                                <Button className="product-list__collapsed__button" rounded secondary onClick={() => setIsCollapsed(false)}>
                                    Show all products
                                    <Icon className="ml-1" name="fa-chevron-down" style="fa-solid" size="fa-sm"/>
                                </Button>
                            </Flex>
                        </div>
                    ) : (!hidePagination && hasMore && (
                        <div className="product-list__load">
                            <Flex alignItems="center" direction="column">
                                <p>{giftsViewed}</p>
                                <Button onClick={refineNext} className="mt-8" rounded secondary>
                                    <Translation pageString="overview_page" stringId="show_more"/>
                                    <Icon className="ml-1" name="fa-chevron-down" style="fa-solid" size="fa-sm"/>
                                </Button>
                            </Flex>
                        </div>
                    ))}
                </>
            )
                : (
                    <div className="content p-0">
                        <p><Translation pageString="overview_page" stringId="no_products_found"/></p>
                        <ButtonStack isSm>
                            <Button type="button"rounded primary onClick={() => window?.location.assign(photoGiftUrl)}>
                                <Translation pageString="404" stringId="naar_hp"/>
                            </Button>
                        </ButtonStack>
                    </div>
                )}
        </Section>
    );
};

export default Results;
