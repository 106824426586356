import type React from 'react';
import { useStats } from 'react-instantsearch-core';
import Translation from '../../../general/Translation/Translation';

export type StatsProps = {
    limit?:number;
};

const Stats: React.FC<StatsProps> = ({ limit }) => {
    const { nbHits } = useStats();

    return <>
        { (!!nbHits)
            && (
                <span className="product-filter-group-results">
                    {limit && limit > 0 && limit < nbHits ? limit : nbHits}
                    {' '}
                    <Translation pageString="overview_page" stringId="gifts_found" />
                </span>
            )}
    </>;
};

export default Stats;
