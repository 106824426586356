import type React from 'react';
import { useEffect, useState } from 'react';
import { useRefinementList } from 'react-instantsearch-core';
import Facet from '../Facet/Facet';
import FilterHeader from './FilterHeader';
import ActiveFilter from '../ActiveFilter/ActiveFilter';

type FilterProps = {
    attribute: string;
    clicked: boolean;
    isCustomSorted: boolean;
    isOpen: boolean;
    label: string;
};

const Filter: React.FC<FilterProps> = ({ attribute, clicked, isCustomSorted, isOpen, label }) => {
    const { items, refine } = useRefinementList({ attribute });

    const newItems = [...items];
    /**
     * If a rule does not define the sorting of the facet, then we do alphabetical sorting ourselves.
     */
    if (!isCustomSorted) {
        newItems.sort((a, b) => {
            const elseCase = b.label.toLowerCase() > a.label.toLowerCase() ? -1 : 0;
            return a.label.toLowerCase() > b.label.toLowerCase() ? 1 : elseCase;
        });
    }

    const [showItems, setShowItems] = useState<boolean>(isOpen);

    useEffect(() => (setShowItems(isOpen)), [isOpen, clicked]);

    const refinedItems = newItems.filter((item) => item.isRefined);

    return (
        <div className="filter">
            <FilterHeader
                toggleItems={() => setShowItems(!showItems)}
                showItems={showItems}
                label={label}
            />
            {!showItems && (
                <div className="filter-active">
                    { refinedItems.map((item) => (
                        <ActiveFilter key={item.label} label={item.label} onRemoveFilter={() => { refine(item.value); }} />
                    ))}
                </div>
            )}
            {showItems && (
                <div className="filter-list">
                    <Facet
                        refine={refine}
                        items={newItems}
                    />
                </div>
            )}
        </div>
    );
};

export default Filter;
