import type { IndexUiState } from 'instantsearch.js';
import type { SearchResults } from 'algoliasearch-helper';
import { isObject } from '../../../js/typeguards';
import moveArrayElement from '../../../js/util/moveArrayElement';
import type { AlgoliaProduct, EavData } from './Input';

export type AlgoliaFilterResults = {
    eavs: EavData[];
    filtersWithRules: string[];
};

export type AlgoliaResultsWithEAVs = Omit<SearchResults<AlgoliaProduct>, 'userData'> & { userData: Record<string, Record<string, string | number>> };

const algoliaResultsToEav = (searchResults: SearchResults | AlgoliaResultsWithEAVs, indexUiState: IndexUiState): AlgoliaFilterResults => {
    const { userData } = searchResults;

    if (!isObject(userData)) {
        return { eavs: [], filtersWithRules: [] };
    }

    let results = Object.values(Object.entries(userData).reduce<Record<string, EavData>>((carry: Record<string, EavData>, [key, value]) => {
        if (key.endsWith('.showInFilter') && Object.keys(value)[0] === 'true' && key.startsWith('filterData.eav.')) {
            const labelRecord = userData[key.replace('.showInFilter', '.label')];

            if (!labelRecord) {
                return carry;
            }

            const label = Object.keys(labelRecord)[0];

            if (!label) {
                return carry;
            }

            const attributeName = key.replace('.showInFilter', '').replace('filterData.eav.', '');

            return {
                ...carry,
                [attributeName]: {
                    attributeName,
                    count: Object.values(value)[0] ?? 0,
                    label,
                } as EavData,
            };
        }
        return carry;
    }, {}));

    results = results.filter((result) => result.attributeName !== 'personalization_type');

    const sortedResults = results.sort((a, b) => {
        const aIsRefined = !!(indexUiState?.refinementList && indexUiState?.refinementList[`filterData.eav.${a.attributeName}.values`]);
        const bIsRefined = !!(indexUiState?.refinementList && indexUiState?.refinementList[`filterData.eav.${b.attributeName}.values`]);

        if (aIsRefined === bIsRefined) {
            return b.count - a.count;
        }

        return aIsRefined
            ? -1
            : 1;
    });

    const facetOrder = searchResults?.renderingContent?.facetOrdering?.facets?.order;

    if (!facetOrder) {
        return { eavs: sortedResults, filtersWithRules: [] };
    }

    const filtersWithRules = facetOrder.map((el) => el.replace('.value_name', '')
        .replace('.values', '')
        .replace('filterData.eav.', ''));

    const findIndexOf = (attributeName: string): number => sortedResults.findIndex((eav) => eav.attributeName === attributeName);

    filtersWithRules.forEach((el, index) => moveArrayElement(sortedResults, findIndexOf(el), index));

    return { eavs: sortedResults, filtersWithRules };
};

export default algoliaResultsToEav;
