const randomString = (
    length: number,
    keyspace = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789',
): string => {
    const charactersLength = keyspace.length;
    return Array.from(Array(length)).reduce(
        (carry: string): string => (
            carry + keyspace.charAt(Math.floor(Math.random() * charactersLength))
        ),
        '',
    );
};

export default randomString;
