import type { RouterProps } from 'instantsearch.js/es/middlewares';
import { history } from 'instantsearch.js/es/lib/routers';
import type { StateMapping } from 'instantsearch.js/es/types';
import type { UiState } from 'instantsearch.js';
import type { IndexUiState } from 'instantsearch.js/es/types/ui-state';
import { createUrlWithOriginalQueryParams } from '../url/routing';

/**
 * This router differs from the Algolia router in the following ways:
 * - The page parameter is never written nor read
 * - The original query string is retained
 */
const getCustomRouter = (originalQueryString: string, stateMapping: StateMapping<UiState, IndexUiState>): RouterProps => ({
    router: history({
        // Starting from the next major version, InstantSearch will not clean up the URL from active refinements when it is disposed.
        // We recommend setting `cleanUrlOnDispose` to false to adopt this change today.
        // To stay with the current behaviour and remove this warning, set the option to true.
        cleanUrlOnDispose: false,

        // We have to write our own URL logic to make sure that non-Algolia related query parameters are not lost
        createURL: ({ location, routeState }) => createUrlWithOriginalQueryParams(originalQueryString, location, routeState),
    }),

    /**
     * We reset 'page' because it causes too many issues when combined with the session storage cache & SSR, such as:
     * https://yoursurprise.atlassian.net/browse/YW-17178
     * https://yoursurprise.atlassian.net/browse/YW-17114
     * https://yoursurprise.atlassian.net/browse/YW-17188
     */
    stateMapping: {
        routeToState: (route) => {
            const routeWithoutPage = { ...route };
            delete routeWithoutPage.page;

            return stateMapping.routeToState(routeWithoutPage);
        },
        stateToRoute: (state) => {
            const routeWithoutPage = { ...stateMapping.stateToRoute(state) };
            delete routeWithoutPage.page;

            return routeWithoutPage;
        },
    },
} as RouterProps);

export default getCustomRouter;
