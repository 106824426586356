import type React from 'react';
import { useCurrentRefinements } from 'react-instantsearch-core';
import ActiveFilter from './ActiveFilter';

const ActiveFilterContainer: React.FC = () => {
    const { items, refine } = useCurrentRefinements({ excludedAttributes: ['price.current', 'query'] });

    return <div className="product-filter-group product-filter-group--active">
        {!!items.length && (
            <>
                {items.map((item) => (
                    (item.refinements ?? []).map((refinement) => (
                        <ActiveFilter
                            key={refinement.label}
                            label={refinement.label}
                            onRemoveFilter={() => refine(refinement)}
                        />
                    ))
                ))}
            </>
        )}
    </div>;
};

export default ActiveFilterContainer;
