import type React from 'react';
import type { ReactNode } from 'react';
import Icon from '../../../../../stories/Atoms/Icon/Icon';

interface ActiveFilterProps {
    label: ReactNode;
    onRemoveFilter: () => void;
}

const ActiveFilter: React.FC<ActiveFilterProps> = ({ label, onRemoveFilter }) => (
    <button
        className="button button--filter-active button--has-icon"
        onClick={onRemoveFilter}
        type="button"
    >
        <span>{label}</span>
        <Icon name='fa-xmark' />
    </button>
);

export default ActiveFilter;
