import type React from 'react';
import Icon from '../../../../../stories/Atoms/Icon/Icon';

interface FilterHeaderProps {
    label: string;
    showItems: boolean;
    toggleItems: () => void;
}
const FilterHeader: React.FC<FilterHeaderProps> = ({ label, showItems, toggleItems }) => (
    <header
        className="filter-header"
        onClick={toggleItems}
        aria-label="filter-header"
    >
        <p>{label}</p>
        <Icon style='fa-regular' name={showItems ? 'fa-chevron-up' : 'fa-chevron-down'} size='fa-xs' />
    </header>
);

export default FilterHeader;
