import type React from 'react';
import { useClearRefinements } from 'react-instantsearch-core';
import Translation from '../../../../general/Translation/Translation';
import Button from '../../../../general/Button/Button';

export const ClearFilter: React.FC = () => {
    const { canRefine, refine } = useClearRefinements();

    return <Button
        isGhost
        disabled={!canRefine}
        onClick={() => refine()}
    >
        <Translation pageString="overview_page" stringId="reset_all"/>
    </Button>;
};

export default ClearFilter;
