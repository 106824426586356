import React from 'react';
import { type PropsWithChildren } from 'react';
import classNames from 'classnames';
import styles from './Flex.module.scss';
import type { Spacings } from '../../UI/constants';

interface Props {
    alignContent?: 'start' | 'center' | 'spaceBetween' | 'spaceAround' | 'end';
    alignItems?: 'start' | 'end' | 'stretch' | 'baseline' | 'center';
    className?: string;
    columnGap?: Spacings;
    direction?: 'row' | 'column' | 'rowReverse' | 'columnReverse';
    justify?: 'start' | 'end' | 'center' | 'spaceBetween' | 'spaceAround' | 'spaceEvenly';
    rowGap?: Spacings;
    wrap?: boolean;
}

const Flex: React.FC<PropsWithChildren<Props>> = ({
    alignContent,
    alignItems,
    children,
    className,
    columnGap,
    direction,
    justify,
    rowGap,
    wrap,
}) => (
    <div className={classNames(
        styles.Flex,
        className,
        columnGap ? styles[`Flex_columnGap_${columnGap}`] : null,
        rowGap ? styles[`Flex_rowGap_${rowGap}`] : null,
        direction ? styles[`Flex_direction_${direction}`] : null,
        alignContent ? styles[`Flex_alignContent_${alignContent}`] : null,
        alignItems ? styles[`Flex_alignItems_${alignItems}`] : null,
        justify ? styles[`Flex_justify_${justify}`] : null,
        wrap ? styles.Flex_wrap : null,
    )}>
        {children}
    </div>
);

export default Flex;
