import type { ChangeEvent } from 'react';
import type React from 'react';
import { useEffect, useState } from 'react';
import { useSortBy } from 'react-instantsearch-core';
import Icon from '../../../../stories/Atoms/Icon/Icon';
import Translation from '../../../general/Translation/Translation';

interface SortByProps {
    clicked: boolean;
    isOpen: boolean;
    items: SortByItem[];
}

export type SortByItem = { label: string; value: string };
const SortBy: React.FC<SortByProps> = ({
    clicked,
    isOpen,
    items,
}) => {
    const { currentRefinement, refine } = useSortBy({ items });

    const refineValue = (event: ChangeEvent<HTMLInputElement>): void => {
        refine(event.target.value);
    };

    const currentSortedItem = items.find((item) => item.value === currentRefinement);
    const [showItems, setShowItems] = useState<boolean>(isOpen);

    useEffect(() => (
        setShowItems(isOpen)
    ), [isOpen, clicked]);

    return (
        <div className="filter">
            <header className="filter-header" onClick={() => setShowItems(!showItems)} aria-label="filter-header">
                <p>
                    <Translation pageString="overview_page" stringId="order_by"/>
                    {' '}
                </p>
                <Icon style='fa-regular' name={showItems ? 'fa-chevron-up' : 'fa-chevron-down'} size='fa-xs' />
            </header>
            {!showItems && (
                <div className="filter-active">
                    <span className="button button--filter-active">{currentSortedItem?.label}</span>
                </div>
            )}
            {showItems && (
                <div className="filter-list">
                    {items.map((item) => (
                        <div className="filter-item" key={item.label} >
                            <div className="filter-item-check">
                                <input
                                    onChange={refineValue}
                                    type="radio"
                                    className="form-radio"
                                    name="sort-by"
                                    id={item.label}
                                    value={item.value}
                                    checked={item.value === currentRefinement}
                                />
                            </div>
                            <label htmlFor={item.label}>
                                {item.label}
                            </label>
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
};

export default SortBy;
