import type { ChangeEvent } from 'react';
import type React from 'react';
import type { useRefinementList } from 'react-instantsearch-core';
import randomString from '../../../../../js/util/randomString';

type FacetProps = Pick<ReturnType<typeof useRefinementList>, 'items' | 'refine'>;

const Facet: React.FC<FacetProps> = ({ items, refine }) => {
    const refineItem = (item: { value: string }) => (e: ChangeEvent<HTMLInputElement>) => {
        e.preventDefault();
        refine(item.value);
    };

    if (items.length === 0) {
        return null;
    }

    return (
        <>
            {items.map((item) => (
                /*
                 * A random string shouldnt be used as a key, but these elements have issues with re-rendering.
                 * This was the only way to get them to properly re-render every update.
                 */
                <div
                    key={randomString(5)}
                    className="filter-item"
                >
                    <div className="filter-item-check">
                        <input
                            id={`filter-${item.label}`}
                            type="checkbox"
                            className="form-checkbox"
                            onChange={refineItem(item)}
                            checked={item.isRefined}
                        />
                    </div>
                    <label htmlFor={`filter-${item.label}`}>
                        {item.label}
                        <span className="filter-item-amount">({item.count})</span>
                    </label>
                </div>
            ))}
        </>
    );
};

export default Facet;
