import type { ParsedQs } from 'qs';
import qs from 'qs';

/**
 * We reset 'page' because it causes too many issues when combined with the session storage cache & SSR, such as:
 * https://yoursurprise.atlassian.net/browse/YW-17178
 * https://yoursurprise.atlassian.net/browse/YW-17114
 * https://yoursurprise.atlassian.net/browse/YW-17188
 */

const urlToUiState = (search: string) => {
    const parsed = qs.parse(search.slice(1));

    delete parsed.page;

    return parsed;
};

const queryParamsToUiState = (queryParams: ParsedQs) => {
    const queryParamsWithoutPage = { ...queryParams };

    delete queryParamsWithoutPage.page;

    return queryParamsWithoutPage;
};

export { urlToUiState, queryParamsToUiState };
