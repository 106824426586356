import type React from 'react';
import { useEffect } from 'react';
import type { PropsWithChildren } from 'react';
import type { Tracker } from '@yoursurprise/segment-analytics';
import type { SearchClient } from 'algoliasearch';
import type { InstantSearchServerState } from 'react-instantsearch-core';
import type { UiState } from 'instantsearch.js';
import { Action, createBrowserHistory } from 'history';
import pubSubJS from 'pubsub-js';
import type { StateMapping } from 'instantsearch.js/es/types';
import type { IndexUiState } from 'instantsearch.js/es/types/ui-state';
import { singleIndex } from 'instantsearch.js/es/lib/stateMappings';
import { window } from '../../../../js/globals';
import AlgoliaProvider from './AlgoliaProvider';
import { Analytics } from '../Analytics/Analytics';
import { SEARCH_PAGE_LOADED, URL_CHANGE } from '../event/eventTypes';
import { urlToUiState } from '../url/urlToUiState';
import getCustomRouter from './AlgoliaRouting';

interface InstantSearchProps {
    algoliaServerState?: InstantSearchServerState;
    currency: string;
    productIndexName: string;
    replicaIndexBase: string;
    searchClient: SearchClient;
    tracker?: Tracker | undefined;
    uiState?: UiState;
}

const buildUrlChangeEvent = (query: string, closeSearch: boolean) => ({ closeSearch, query });

const InstantSearchWrapper: React.FC<PropsWithChildren<InstantSearchProps>> = (
    {
        algoliaServerState,
        children,
        currency,
        productIndexName,
        replicaIndexBase,
        searchClient,
        tracker,
        uiState,
    },
) => {
    const stateMapping: StateMapping<UiState, IndexUiState> = singleIndex(productIndexName);
    const routing = getCustomRouter(window?.location.search ?? '', stateMapping);

    const onSearchStateChange: Parameters<typeof AlgoliaProvider>[0]['onStateChange'] = ({ setUiState, uiState: newUiState }) => {
        const currentState = newUiState[productIndexName];

        if (currentState && currentState.query) {
            tracker?.trackProductsSearched(currentState.query);
        }

        setUiState(newUiState);
    };

    pubSubJS.subscribe(SEARCH_PAGE_LOADED, () => {
        pubSubJS.publish(URL_CHANGE, buildUrlChangeEvent((uiState ?? {})[productIndexName]?.query?.toString() ?? '', false));
    });

    useEffect(() => {
        const browserHistory = createBrowserHistory();
        const unListen = browserHistory.listen(({ action, location }) => {
            if (action === Action.Pop) {
                const newState = urlToUiState(location.search);
                pubSubJS.publish(URL_CHANGE, buildUrlChangeEvent(newState.query?.toString() ?? '', true));
            }
        });

        return () => {
            unListen();
        };
    }, [productIndexName, replicaIndexBase]);

    useEffect(() => {
        const initialIndexState = (uiState ?? {})[productIndexName];

        if (typeof initialIndexState?.query === 'string' && initialIndexState?.query !== '') {
            tracker?.trackProductsSearched(initialIndexState?.query);
        }
    }, [productIndexName, tracker, uiState]);

    return (
        <AlgoliaProvider
            routing={typeof window !== 'undefined' ? routing : false}
            algoliaSearchClient={searchClient}
            indexName={productIndexName}
            initialUiState={uiState}
            algoliaServerState={algoliaServerState}
            onStateChange={onSearchStateChange}
        >
            {children}
            <Analytics currency={currency} tracker={tracker}/>
        </AlgoliaProvider>);
};
export default InstantSearchWrapper;
