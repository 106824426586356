import type React from 'react';
import Icon from '../../../../stories/Atoms/Icon/Icon';

interface FilterNavigationProps {
    isSorting?: boolean;
    item: string;
    openFilter: () => void;
}

const FilterNavigation: React.FC<FilterNavigationProps> = ({ isSorting, item, openFilter }) => (
    <button
        type="button"
        className={isSorting ? 'button button--link button--has-icon' : 'button button--filter'}
        onClick={openFilter}
    >
        <span>{item}</span>
        {isSorting && (
            <Icon style='fa-regular' name='fa-chevron-down' size='fa-xs' />
        )}
    </button>
);

export default FilterNavigation;
