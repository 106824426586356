import type React from 'react';
import Filter from './Filter/Filter';
import { ClearFilter } from './ClearFilter/ClearFilter';
import type { SortByItem } from '../SortBy/SortBy';
import Translation from '../../../general/Translation/Translation';
import type { EavData } from '../Input';
import ButtonStack from '../../../general/Button/ButtonStack';
import Button from '../../../general/Button/Button';
import Icon from '../../../../stories/Atoms/Icon/Icon';
import SortBy from '../SortBy/SortBy';

interface FilterListProps {
    clicked: boolean;
    filtersWithRules: string[];
    items: SortByItem[];
    onFilterClose: () => void;
    popularFilters: EavData[];
    visibility: boolean[];
}

const FilterList: React.FC<FilterListProps> = ({
    clicked,
    filtersWithRules,
    items,
    onFilterClose,
    popularFilters,
    visibility,
}) => <aside className="drawer">
    <header className="drawer-header">
        <span className="h4 drawer-header-title">
            <Translation pageString="overview_page" stringId="filter_mobile_title"/>
        </span>
        <button
            onClick={onFilterClose}
            type="button"
            aria-label="filter-close"
            className="drawer-header-button button button--clean button--has-icon"
        >
            <Icon name='fa-xmark' />
        </button>
    </header>
    <div className='drawer-content'>
        <SortBy
            items={items}
            isOpen={visibility[0] ?? false}
            clicked={clicked}
        />
        {popularFilters
                    && popularFilters.map((dynamicFilter, i) => (
                        <Filter
                            key={dynamicFilter.attributeName}
                            attribute={`filterData.eav.${dynamicFilter.attributeName}.values`}
                            label={dynamicFilter.label}
                            isOpen={visibility.slice(1)[i] ?? false}
                            clicked={clicked}
                            isCustomSorted={filtersWithRules.includes(dynamicFilter.attributeName)}/>
                    ))}
    </div>
    <div className="drawer-footer">
        <ButtonStack isRow isFluid>
            <ClearFilter/>
            <Button
                isPrimary
                onClick={onFilterClose}
            >
                <Translation pageString="overview" stringId="show_gifts"/>
            </Button>
        </ButtonStack>
    </div>
</aside>;

export default FilterList;
