import qs from 'qs';

const createUrlWithOriginalQueryParams = (originalQueryString: string, location: Location, routeState: Record<string, unknown>) => {
    const { hash, origin, pathname } = location;
    const originalQueryParams = qs.parse(originalQueryString, { ignoreQueryPrefix: true });

    // Make sure we don't retain any Algolia state from the original query params, as we don't want to restore them when making filter changes
    delete originalQueryParams.query;
    delete originalQueryParams.range;
    delete originalQueryParams.refinementList;
    delete originalQueryParams.sortBy;

    const queryString = qs.stringify({
        ...originalQueryParams,
        ...routeState,
    }, { addQueryPrefix: true });

    return `${origin}${pathname}${queryString}${hash}`;
};

export { createUrlWithOriginalQueryParams };
